<template>
  <div class="merchantcenter_page" v-loading="loading">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        min-height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">
        {{ language.MerchantInformation }}
      </div>
      <!-- 基本资讯 -->
      <div
        style="
          border-bottom: 3px solid #efefef;
          padding: 11px 30px;
          margin-top: 10px;
          font-size: 20px;
          font-weight: 600;
        "
      >
        {{ language.Essential_Information }}
      </div>
      <div style="display: flex; margin-top: 30px; padding: 0 30px; flex-wrap: wrap">
        <!-- 商户名 -->
        <div class="input_box">
          <!-- <span><span style="color: red">*</span></span> -->
          <!-- span><span style="color: red">*</span>{{ language.Merchant_Name }}</span> -->
          <div v-if="type == 1 || type == 2">
            {{ language.Merchant_Name }}: {{ Merchant_Name }}
          </div>
          <div style="display: flex" v-if="!type || type == 3">
            <span style="color: red">*</span>
            <el-input
              style="border: none"
              v-model="Merchant_Name"
              :placeholder="language.Merchant_Name"
            ></el-input>
          </div>
        </div>
        <!-- 商户简称 -->
        <div class="input_box">
          <!-- <span
            ><span style="color: red">*</span>{{ language.Merchant_Abbreviation }}</span
          > -->
          <div v-if="type == 1 || type == 2">
            {{ language.Merchant_Abbreviation }}: {{ Merchant_Abbreviation }}
          </div>
          <div style="display: flex" v-if="!type || type == 3">
            <span style="color: red">*</span>
            <el-input
              style="border: none"
              v-model="Merchant_Abbreviation"
              :placeholder="language.Merchant_Abbreviation"
            ></el-input>
          </div>
        </div>
        <!-- 店铺类型 -->
        <div class="input_box">
          <!-- <span><span style="color: red">*</span>{{ language.StoreType }}</span> -->
          <div v-if="type == 1 || type == 2" style="display: flex">
            {{ language.StoreType }}:
            <div
              v-if="
                languagetype == 'CHS' && options2.find((item) => item.id == Merchant_Type)
              "
            >
              {{ options2.find((item) => item.id == Merchant_Type).title }}
            </div>
            <div
              v-if="
                languagetype == 'TC' && options2.find((item) => item.id == Merchant_Type)
              "
            >
              {{ options2.find((item) => item.id == Merchant_Type).hk_title }}
            </div>
            <div
              v-if="
                languagetype == 'EN' && options2.find((item) => item.id == Merchant_Type)
              "
            >
              {{ options2.find((item) => item.id == Merchant_Type).en_title }}
            </div>
          </div>
          <div v-if="!type || type == 3" style="width: 100%; display: flex">
            <span style="color: red">*</span>
            <el-select
              style="width: 100%"
              v-if="languagetype == 'CHS'"
              v-model="Merchant_Type"
              :placeholder="language.StoreType"
            >
              <el-option
                v-for="item in options2"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 100%"
              v-if="languagetype == 'TC'"
              v-model="Merchant_Type"
              :placeholder="language.StoreType"
            >
              <el-option
                v-for="item in options2"
                :key="item.id"
                :label="item.hk_title"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              style="width: 100%"
              v-if="languagetype == 'EN'"
              v-model="Merchant_Type"
              :placeholder="language.StoreType"
            >
              <el-option
                v-for="item in options2"
                :key="item.id"
                :label="item.en_title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 商户类型 -->
        <div class="input_box">
          <!-- <span><span style="color: red">*</span>{{ language.Merchant_Type }}</span> -->
          <div v-if="type == 1 || type == 2">
            {{ language.Merchant_Type }}: {{ shoplist[shop_type].label }}
          </div>
          <div style="width: 100%; display: flex" v-if="!type || type == 3">
            <span style="color: red">*</span>
            <el-select
              style="width: 100%"
              v-model="shop_type"
              :placeholder="language.Merchant_Type"
            >
              <el-option
                v-for="item in shoplist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 币种 -->
        <div class="input_box">
          <!-- <span><span style="color: red">*</span>{{ language.Currency }}</span> -->
          <div v-if="type == 1 || type == 2">{{ language.Currency }}: {{ currency }}</div>
          <div style="width: 100%; display: flex" v-if="!type || type == 3">
            <span style="color: red">*</span>
            <el-select style="width: 100%" v-model="currency" placeholder="">
              <el-option
                v-for="item in Currencylist"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="input_box">
          <!-- <span
            ><span style="color: red">*</span
            >{{ language.Email_of_person_in_charge }}</span
          > -->
          <div>{{ language.MerchantID }}: {{ id }}</div>
          <!-- <el-input v-if="!type || type == 3" style="border: none;" v-model="Email_of_person_in_charge" placeholder=""></el-input> -->
        </div>
      </div>
      <!-- 负责人信息 -->
      <div
        style="
          border-bottom: 3px solid #efefef;
          padding: 11px 30px;
          margin-top: 10px;
          font-size: 20px;
          font-weight: 600;
        "
      >
        {{ language.Person_in_charge_information }}
      </div>
      <!-- 负责人姓名 -->
      <div style="display: flex; margin-top: 30px; padding: 0 30px; flex-wrap: wrap">
        <div class="input_box">
          <!-- <span
            ><span style="color: red">*</span
            >{{ language.Name_of_person_in_charge }}</span
          > -->
          <div v-if="type == 1 || type == 2">
            {{ language.Name_of_person_in_charge }}: {{ Name_of_person_in_charge }}
          </div>
          <div style="display: flex" v-if="!type || type == 3">
            <span style="color: red">*</span>
            <el-input
              style="border: none"
              v-model="Name_of_person_in_charge"
              :placeholder="language.Name_of_person_in_charge"
            ></el-input>
          </div>
        </div>
        <div class="input_box">
          <!-- <span
            ><span style="color: red">*</span
            >{{ language.Email_of_person_in_charge }}</span
          > -->
          <div>
            {{ language.Email_of_person_in_charge }}: {{ Email_of_person_in_charge }}
          </div>
          <!-- <el-input v-if="!type || type == 3" style="border: none;" v-model="Email_of_person_in_charge" placeholder=""></el-input> -->
        </div>
        <!-- 负责人电话 -->
        <div class="input_box" v-if="showphone">
          <!-- <span>{{ language.Mobile_number_of_the_responsible_person }}</span> -->
          <div v-if="type == 1 || type == 2">
            {{ language.Mobile_number_of_the_responsible_person }}:
            {{ Mobile_number_of_the_responsible_person }}
          </div>
          <div>
            <el-input
              v-if="!type || type == 3"
              style="border: none"
              v-model="Mobile_number_of_the_responsible_person"
              :placeholder="language.Mobile_number_of_the_responsible_person"
            ></el-input>
          </div>
        </div>
      </div>
      <!-- 上传图片 -->
      <div
        style="border-bottom: 3px solid #efefef; padding: 11px 30px; margin-top: 30px"
        v-if="type != 2"
      >
        {{ language.Qualificationupload }}
      </div>
      <div
        style="display: flex; margin-top: 30px; padding: 0 30px; flex-wrap: wrap"
        v-if="type != 2"
      >
        <!-- 营业执照 -->
        <div class="input_box2">
          <span><span style="color: red">*</span>{{ language.businesslicense }}</span>
          <div class="imgdiv" v-if="type == 1 || type == 2">
            <img class="imgdiv" :src="businesslicense" alt="" />
          </div>
          <el-upload
            v-if="!type || type == 3"
            class="avatar-uploader"
            :show-file-list="false"
            :auto-upload="false"
            action=""
            :on-change="onChangeUpload1"
            :headers="headers"
          >
            <img v-if="businesslicense" :src="businesslicense" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <!-- 身份证正面 -->
        <div class="input_box2" v-if="type != 2">
          <span
            ><span style="color: red">*</span>{{ language.FrontofcorporateIDcard }}</span
          >
          <div class="imgdiv" v-if="type == 1 || type == 2">
            <img class="imgdiv" :src="FrontofcorporateIDcard" alt="" />
          </div>
          <el-upload
            v-if="!type || type == 3"
            class="avatar-uploader"
            action=""
            :auto-upload="false"
            :show-file-list="false"
            :on-change="onChangeUpload2"
            :headers="headers"
          >
            <img
              v-if="FrontofcorporateIDcard"
              :src="FrontofcorporateIDcard"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <!-- 身份证反面 -->
        <div class="input_box2" v-if="type != 2">
          <span
            ><span style="color: red">*</span
            >{{ language.ThereversesideofthecorporateIDcard }}</span
          >
          <div class="imgdiv" v-if="type == 1 || type == 2">
            <img class="imgdiv" :src="ThereversesideofthecorporateIDcard" alt="" />
          </div>
          <el-upload
            class="avatar-uploader"
            v-if="!type || type == 3"
            :show-file-list="false"
            action=""
            :auto-upload="false"
            :on-change="onChangeUpload3"
            :headers="headers"
          >
            <img
              v-if="ThereversesideofthecorporateIDcard"
              :src="ThereversesideofthecorporateIDcard"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
      <!-- 账户余额 -->
      <div
        v-if="type == 2"
        style="
          border-bottom: 3px solid #efefef;
          padding: 11px 30px;
          margin-top: 10px;
          font-size: 20px;
          font-weight: 600;
        "
      >
        {{ language.Account_balance }}
      </div>
      <div
        class="moneybox"
        v-if="type == 2"
        style="display: flex; margin-top: 30px; padding: 0 30px; flex-wrap: wrap"
      >
        <div>
          <span>CNY{{ language.balance }}: {{ rmb_money }}</span>
        </div>
        <div>
          <span>MRY{{ language.balance }}: {{ money2 }}</span>
        </div>
        <div>
          <span>THB{{ language.balance }}: {{ thb_money }}</span>
        </div>
        <div>
          <span>USDT{{ language.balance }}: {{ usdt_money }}</span>
        </div>
        <div>
          <span>INR{{ language.balance }}: {{ inr_money }}</span>
        </div>
        <div>
          <span>WanBi{{ language.balance }}: {{ wanbi_money }}</span>
        </div>
        <div>
          <span>GoBi{{ language.balance }}: {{ gobi_money }}</span>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <div v-if="!type || type == 3">
          <!-- 提交按钮 -->
          <el-button type="primary" @click="binding()">{{ language.submitto }}</el-button>
        </div>
        <div v-if="type == 1">
          <!-- 审核 -->
          <el-button type="primary">{{ language.inreview }}</el-button>
        </div>
      </div>
      <el-dialog
        :close-on-click-modal="false"
        :title="language.Verifymailbox"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
        center
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 40px;
          "
        >
          <!-- 邮箱验证码 -->
          <div style="margin-top: 20px; width: 70%">
            <div>{{ language.VerificationCode }}</div>
            <div style="display: flex">
              <el-input
                style="border: none"
                v-model="Mobilephoneverificationcode"
                placeholder=""
              ></el-input>
              <el-button v-if="time" type="info">{{ time }}</el-button>
              <el-button v-if="!time" type="primary" @click="Send_code()">{{
                language.Send_code
              }}</el-button>
            </div>
          </div>
          <div style="margin-top: 50px">
            <el-button style="width: 100px" type="primary" @click="bindingBtn()">{{
              language.binding
            }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  infosave,
  info,
  user,
  smssend,
  userchangemobile,
  emssend,
  classify,
  commonuploadBase64,
} from "../../api/index.js";
// import { uploadImgToBase64 } from "../../common/units.js";
export default {
  data() {
    return {
      id: "",
      language: "",
      languagetype: null,
      loading: true,
      Merchant_Name: "", //商户名称
      Merchant_Abbreviation: "", //商户简称
      Merchant_Type: null, //店铺类型
      shop_type: null, //商户类型
      Name_of_person_in_charge: "", //负责人姓名
      Email_of_person_in_charge: "", //负责人邮箱
      Mobile_number_of_the_responsible_person: "", //负责人手机号
      Mobilephoneverificationcode: "", //手机验证码
      type: false, //判断是否绑定信息
      // Selectcountry: "",//选择国家
      phoneshow: false, //是否验证手机
      showphone: true,
      options2: [],
      token: "",
      headers: "",
      shoplist: [
        {
          value: 0,
          label: "",
        },
        {
          value: 1,
          label: "",
        },
        {
          value: 2,
          label: "",
        },
        {
          value: 3,
          label: "",
        },
      ],
      countrylist: [
        {
          value: 1,
          label: "香港",
        },
        {
          value: 2,
          label: "马来西亚",
        },
        {
          value: 3,
          label: "新加坡",
        },
        {
          value: 4,
          label: "泰国",
        },
        {
          value: 5,
          label: "越南",
        },
      ],
      currency: "MYR",
      Currencylist: ["CNY", "MYR", "THB", "USDT", "INR", "WanBi", "GoBi"],
      money2: "",
      thb_money: "",
      usdt_money: "",
      inr_money: "", //印度币
      wanbi_money: "", //wanbi
      gobi_money: "", //gobi
      rmb_money: "", //人民币
      dialogVisible: false,
      time: 60, //倒计时
      businesslicense: "", //营业执照
      FrontofcorporateIDcard: "", //身份证正面地址
      ThereversesideofthecorporateIDcard: "", //身份证反面地址
    };
  },
  created() {
    this.init();
    this.changelanguage();
    this.token = localStorage.getItem("token");
    this.headers = { token: this.token };
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    // Selectcountry() {
    //     if (this.Selectcountry == 1) {
    //         this.phoneshow = true
    //     } else {
    //         this.phoneshow = false
    //     }
    // }
  },

  methods: {
    init() {
      let that = this;
      // 获取邮箱
      user().then((res) => {
        if (res.code == 1) {
          // if (res.data.userinfo.pid == 0) {
          //   this.Email_of_person_in_charge = res.data.userinfo.username;
          // }
          this.currency = res.data.userinfo.currency;
          this.money2 = res.data.userinfo.money;
          this.thb_money = res.data.userinfo.thb_money;
          this.usdt_money = res.data.userinfo.usdt_money;
          this.inr_money = res.data.userinfo.inr_money;
          this.wanbi_money = res.data.userinfo.wanbi_money;
          this.gobi_money = res.data.userinfo.gobi_money;
          // this.id = res.data.userinfo.id;
          this.rmb_money = res.data.userinfo.cny_money;
        }
      });
      // 获取店铺
      classify().then((res) => {
        if (res.code == 1) {
          this.options2 = res.data.list;
        }
      });
      // 获取认证信息
      info().then((res) => {
        this.loading = false;
        if (res.code == 1) {
          if (res.data) {
            this.Email_of_person_in_charge = res.data.linkman_email;
            this.id = res.data.mch_id;
            this.Merchant_Name = res.data.name;
            this.Merchant_Abbreviation = res.data.short_name;
            this.Merchant_Type = res.data.product_type;
            this.Name_of_person_in_charge = res.data.linkman;
            this.shop_type = Number(res.data.shop_type);
            this.businesslicense = res.data.business_license_link; //营业执照
            this.FrontofcorporateIDcard = res.data.idcard_passport_link.split(",")[0];
            this.ThereversesideofthecorporateIDcard = res.data.idcard_passport_link.split(
              ","
            )[1];
            if (res.data.linkman_phone) {
              this.Mobile_number_of_the_responsible_person = res.data.linkman_phone;
              this.showphone = true;
            } else {
              this.showphone = false;
            }
            if (res.data.status != 2) {
              this.showphone = true;
            }
            // this.language.Merchantauthenticationfailedpleaseresubmittheinformation
            that.type = res.data.status;
            if (that.type == 3) {
              this.$alert(
                "<div>" +
                  this.language.Merchantauthenticationfailedpleaseresubmittheinformation +
                  "</div><div>" +
                  this.language.failurereason +
                  ":" +
                  res.data.result_info +
                  "</div>",
                this.language.prompt,
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: this.language.determine,
                }
              );
            }
          } else {
            this.showphone = true;
          }
        }
      });
    },
    // 切换语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
      this.shoplist[0].label = this.language.other; //其他
      this.shoplist[1].label = this.language.individual; //个人
      this.shoplist[2].label = this.language.corporation; //公司
      this.shoplist[3].label = this.language.terrace; //平台
    },
    // 转化图片
    onChangeUpload1(e) {
      this.getBase64(e.raw).then((res) => {
        let data = {
          file: res,
        };
        commonuploadBase64(data).then((res2) => {
          this.businesslicense = res2.data.url;
        });
      });
    },
    onChangeUpload2(e) {
      this.getBase64(e.raw).then((res) => {
        let data = {
          file: res,
        };
        commonuploadBase64(data).then((res2) => {
          this.FrontofcorporateIDcard = res2.data.url;
        });
      });
    },
    onChangeUpload3(e) {
      this.getBase64(e.raw).then((res) => {
        let data = {
          file: res,
        };
        commonuploadBase64(data).then((res2) => {
          this.ThereversesideofthecorporateIDcard = res2.data.url;
        });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    // 左上角绑定验证
    binding() {
      // 商户名
      if (!this.Merchant_Name) {
        this.$message({
          message: this.language.Merchant_Name + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 商户简称
      if (!this.Merchant_Abbreviation) {
        this.$message({
          message: this.language.Merchant_Abbreviation + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      //店铺类型
      if (this.Merchant_Type == null) {
        this.$message({
          message: this.language.StoreType + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      //商户类型
      if (this.shop_type == null) {
        this.$message({
          message: this.language.Merchant_Type + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 负责人姓名
      if (!this.Name_of_person_in_charge) {
        this.$message({
          message: this.language.Name_of_person_in_charge + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 营业执照
      if (!this.businesslicense) {
        this.$message({
          message: this.language.businesslicense + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 身份证正面
      if (!this.FrontofcorporateIDcard) {
        this.$message({
          message: this.language.FrontofcorporateIDcard + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      // 身份证反面
      if (!this.ThereversesideofthecorporateIDcard) {
        this.$message({
          message:
            this.language.ThereversesideofthecorporateIDcard +
            this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      this.$confirm(
        this.language.Areyousuretosubmitinformationformerchantauthentication,
        this.language.prompt,
        {
          confirmButtonText: this.language.determine,
          cancelButtonText: this.language.Cancel,
          type: "warning",
        }
      )
        .then(() => {
          this.saveinfo();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Canceled,
          });
        });
      // this.dialogVisible = true
      // console.log(this.Email_of_person_in_charge)
      // if (this.phoneshow == 1) {
      //     if (!this.Mobile_number_of_the_responsible_person) {
      //         this.$message({
      //             message: this.language.Mobile_number_of_the_responsible_person + this.language.cannotbeempty,
      //             type: 'warning'
      //         });
      //         return
      //     }
      //     this.Send_code()
      // }else{
      //     // this.Send_Email_code()
      //     this.saveinfo()
      // }
    },
    // 发送邮箱验证码
    mail_code() {
      let data = {
        mobile: this.Email_of_person_in_charge,
        event: "changemobile",
      };
      emssend(data).then((res) => {
        if (res.code == 1) {
          if (!this.dialogVisible) {
            this.dialogVisible = true;
          }
          this.settimeInterval();
        }
      });
    },
    // 发送手机验证码
    Send_code() {
      let data = {
        mobile: this.Mobile_number_of_the_responsible_person,
        event: "changemobile",
      };
      smssend(data).then((res) => {
        if (res.code == 1) {
          if (!this.dialogVisible) {
            this.dialogVisible = true;
          }
          this.settimeInterval();
        }
      });
    },
    // 倒计时
    settimeInterval() {
      let that = this;
      that.time = 60;
      this.settime = setInterval(() => {
        that.time = that.time - 1;
        if (that.time == 0) {
          clearInterval(that.settime);
        }
      }, 1000);
    },
    // 绑定邮箱
    bindingBtn() {
      this.saveinfo();
    },
    // 绑定手机号
    bindingBtn2() {
      let data = {
        mobile: this.Mobile_number_of_the_responsible_person,
        captcha: this.Mobilephoneverificationcode,
      };
      userchangemobile(data).then((res) => {
        if (res.code == 1) {
          this.saveinfo();
        }
      });
    },
    saveinfo() {
      let data = {
        name: this.Merchant_Name, //商户名称
        short_name: this.Merchant_Abbreviation, //商户简称
        product_type: this.Merchant_Type, //店铺类型
        linkman: this.Name_of_person_in_charge, //负责人姓名
        linkman_email: this.Email_of_person_in_charge, //负责人邮箱
        linkman_phone: this.Mobile_number_of_the_responsible_person, //负责人手机号
        shop_type: this.shop_type,
        currency: this.currency, //币种
        business_license_link: this.businesslicense, //营业执照
        idcard_passport_link:
          this.FrontofcorporateIDcard + "," + this.ThereversesideofthecorporateIDcard, //身份证正反面地址
      };
      infosave(data).then((res) => {
        if (res.code == 1) {
          this.init();
          this.dialogVisible = false;
          // this.$store.commit("changeinfo", false);
          this.$message({
            message: this.language.Submittedsuccessfully,
            type: "success",
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_box {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 350px;
  /* border: 1px solid #dcdfe6; */
  /* border-bottom: 1px solid #000000; */
  padding-bottom: 1px;
  margin-bottom: 10px;
}
.input_box2 {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 350px;
}
.input_box2 > span {
  color: #b7b7b7;
}

.input_box > span {
  color: #b7b7b7;
}

.input_box > div {
  height: 40px;
  line-height: 40px;
}

.input_box >>> .el-input__inner {
  /* border: none; */
  border-radius: 0px;
}
* >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
* >>> .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
* >>> .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.imgdiv {
  width: 178px;
  height: 178px;
}
.moneybox > div {
  margin-right: 70px;
}
</style>
